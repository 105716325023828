<template>
    <div class="main">
        <QsNavbar title="领导力思维专区" :leftArrow="false">
            <template #right>
                <van-icon name="search"  size="20px" @click="showSearch=!showSearch"/>
            </template>
        </QsNavbar>
        <form class="search-form" v-if="showSearch">
        <van-search
            v-model="post.keyword"
            show-action
            placeholder="请输入搜索关键词"
            @search="onSearch"
            @cancel="showSearch=false"
        />
        </form> 
        <swiper class="banner" :options="swiperOption" ref="levalSwiper">
            <swiper-slide v-for="(item, index) in swipes" :key="index">
                <a :href="item.url" v-if="item.url">
                    <img :src="item.img"  style="max-width:100%"/>
                </a>
                <img :src="item.img"  style="max-width:100%" v-else/>
            </swiper-slide>
        </swiper>
        <van-tabs v-model="post.cid" @click="onSwitchCategory" class="leader-category-tabs">
            <van-tab v-for="row in categories" :title="row.name" :key="row.id" :name="row.id">
                <div class="q-box">
                    <van-list class="body"
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad">
                        <router-link :to="{path:'/leader/show',query:{id:item.id}}" tag="div" class="d-flex list-item article-box " v-for="item in list" :key="item.id" >
                            <div class="flex-1">
                                <div>{{item.title}}</div>
                            </div>
                            <img :src="item.img" class="thumb" width="120px" height="80px"/>
                        </router-link>
                    </van-list>
                </div>
            </van-tab>
        </van-tabs>
        <QsTabbar tab-id="leader"></QsTabbar>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
export default {
    data(){
        return{
            categories:[],
            swipes:[],
            list:[],
            showSearch:false,
            loading: true,
            finished: false,
            post:{
                cid:0,
                page:0,
                keyword:""
            },
            swiperOption: {
                // slidesPerView: 'auto',
                centeredSlides: true,
                // spaceBetween: 10,
            },
        }
    },
    mounted(){
        this.onLoad();
    },
    methods:{
        onLoad(){
            this.post.page++;
            this.$ajax.post("client/leader/lists",this.post).then(res=>{
                this.categories = res.data.categories;
                this.swipes = res.data.swipes;
                this.list = this.list.concat(res.data.list.data);
                this.loading = false;
                if(res.data.list.current_page>=res.data.list.last_page){
                    this.finished = true;
                }
            }).catch(e=>{});
        },
        onSearch(){
            this.post.page=0;
            this.list.length = 0
            this.onLoad();
            this.showSearch=false;
        },
        onSwitchCategory(name, title){
            this.post.page = 0;
            this.finished=false;
            this.list.splice(0,this.list.length);
            this.onLoad();
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
}
</script>

<style lang="scss">

</style>

<style lang="scss" scoped>

.search-form{
    position: relative;;
    z-index: 9999;
    width: 100%;
}


</style>

