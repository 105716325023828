<template>
    <form action="/">
      <van-search v-model="searchVal" placeholder="输入产品编号、名称" :show-action="showAction" clearable @clear="onClear" @focus="onFocus" @search="onSearch">
        <template #left v-if="showLogo">
            <div class="logo"/>
        </template>
        <template #action>
            <slot name="action">
                <div @click="onSearch">搜索</div>
            </slot>
        </template>
      </van-search>
    </form>
</template>

<script>
import { Search } from 'vant';
import storage from '../utils/storage';

export default {
    name: 'search',
    components: {
        [Search.name]:Search
    },
    props:{
        showLogo:{
            type:Boolean,
            default:false,
        },
        keyword:{
            type:String,
            default:'',
        },
        customSearch:{
            type:Boolean,
            default:false
        },
        showAction:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            searchVal:'',
        }
    },
    created(){
        
    },
    mounted(){
        this.searchVal = this.keyword;
    },
    methods:{
        onSearch(keyword){
            if(!this.$util.isString(keyword)){
                keyword = this.searchVal;
            }
            var histories = storage.get("search-history") || ',';
            if(histories.indexOf(","+keyword+",")==-1){
                histories=","+keyword+histories;
                storage.set("search-history",histories);
            }

            if(this.customSearch){
                this.$emit("search",keyword);
            }else{
                this.$router.push({path:'/product/list',query:{'keyword':keyword}});
            }
        },
        onFocus(){
            this.$emit("focus");
        },
        onClear(){
            console.log("onClear");
        }
    }

}
</script>


<style lang="scss" scoped>
.van-search__content{border-radius: 100px;}
// ::v-deep .van-field__control[type=search]{flex:1;}
</style>

