<template>
  <div class="product-body">
    <QsNavbar title="Molex" :leftArrow="false">
      <template #title>        
        <div class="head-logo">
          <van-image
            width="100"
            :src="require('@/assets/logo2.png')"
          />
        </div>
      </template>
    </QsNavbar>
    <qs-search @focus="onJumpSearch"></qs-search>
    <div class="q-box">
    <van-swipe :autoplay="3000" >
      <van-swipe-item v-for="(item, index) in swipes" :key="index">
        <a :href="item.url" v-if="item.url">
          <van-image fit="cover"  :src="item.img" />
        </a>
        <van-image fit="cover"  :src="item.img" v-else/>
      </van-swipe-item>
    </van-swipe>   
  </div>
      <van-tabs class="product-category-tabs q-box-x van-tabs__wrap--scrollable1" :ellipsis="false" >
          <van-tab v-for="row in categories" :title="row.name" :key="row.id" :name="row.id" scrollspy  >
            <div class="q-box">
              <van-cell class="category-box" :title="item.name" is-link  :to="{path:'/product/list',query:{cid:item.id}}"  v-for="item in row.subs" :key="item.id">
                <template #icon>
                  <van-icon :name="item.icon" size="18px" />
                </template>
              </van-cell>
            </div>
          </van-tab>
      </van-tabs>
    <QsTabbar tab-id="home" group="product"></QsTabbar>
  </div>
</template>

<script>
// @ is an alias to /src

import QsSearch from '@components/qs-search'


// import Vue from 'vue'
// import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/swiper.esm'
// import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
// SwiperClass.use([Pagination, Mousewheel, Autoplay])
// Vue.use(getAwesomeSwiper(SwiperClass))
// const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
// import 'swiper/swiper-bundle.css'

import { wexinShare } from '@/utils/weixin.js';

export default {
  name: 'Home',
  components: {
    QsSearch,        
    // Swiper,
    // SwiperSlide
   },
   data(){
     return{
      categories:[],
      swiperOption: {
          // slidesPerView: 'auto',
          centeredSlides: true,
          // spaceBetween: 10,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
      },
      swipes:[],
      wxConfig:{}
     }
   },
  created(){
  },
  mounted(){
    this.onLoad();
  },
  methods:{
    onLoad(){
      this.$ajax.get("client/home/index").then(res=>{
        this.swipes = res.data.swipes;
        this.categories = res.data.categories;
        // this.categories.push({id:0,name:'ssss'})
        if(res.data.weixin){
          this.wxConfig = res.data.weixin;
          this.weixinInit();
        }

      }).catch(e=>{
        console.log(e);
      });
    },
    onLeftClick(){
      this.$router.push({path:'/',query:{cid:this.pid}});
    },
    onJumpSearch(){
      this.$router.push({path:'/search'})
    },
    weixinInit(){
      //分享数据，这段主要是为了在hash模式下分享出去的链接不被浏览器截取，保证完全把链接分享出去
      var url = window.location.href.split('#')[0];
      // var shareWxLink = encodeURIComponent(url);加密
      let shareData = {
          title: 'Molex', // 分享标题
          desc:'Molex产品中心',
          link: url,
          // link: window.location.href,
          imgUrl: url+'static/images/logo.png' // 分享图标
      };
      //引用
      wexinShare(this.wxConfig, shareData);
    }
  },
}
</script>

<style lang="scss" scoped>
.head-logo1{background: url('~@/assets/logo2.png') center center  no-repeat;height: 25px;width: 100px;}
.van-hairline--bottom::after{border-bottom:none;}
.product-category-tabs .q-box{padding-left:11px;padding-right:11px;}
::v-deep .van-search{padding: 2px 12px 15px;}
</style>