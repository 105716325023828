<template>
    <div class="product-body">
        <QsNavbar title="搜索" ></QsNavbar>
        <qs-search :showLogo="false" ref="qsSearch" class="b-b-gray" showAction></qs-search> 
        <van-cell title="搜索历史" class="search-title" center>
            <template #right-icon>
                <van-icon name="delete-o" @click="onClear" />
            </template>
        </van-cell>    
        <div class="search-list q-box">
            <van-tag v-for="item in histories" :key="item" center clickable  @click="onSearch(item)">{{item}}</van-tag>
        </div>
    </div>
</template>

<script>
import storage from '../../utils/storage';
import QsSearch from '@components/qs-search'
export default {
  components: {
    QsSearch
   },
   data(){
     return{
         historyVal:'',
     }
   },
    mounted(){
        this.historyVal = storage.get("search-history") || ",";

    },
   methods:{
       onSearch(keywords){
           this.$refs['qsSearch'].onSearch(keywords);
       },
       onRemove(keywords){
            this.historyVal = this.historyVal.replace(","+keywords+",",",");
            storage.set("search-history",this.historyVal);
       },
       onClear(){
           this.historyVal = '';
            storage.set("search-history",this.historyVal);
       }
   },
   computed:{
       histories(){
        var arr = this.historyVal.split(",");
        var list = [];
        arr.forEach(p=>{
            if(p){
                list.push(p);
            }
        })
        return list;
       }
   }
}
</script>

<style lang="scss">
.search-search{border-bottom:1px solid rgba(234, 234, 234, 100);}
.search-title::after{border-bottom:none;}
.search-list .van-tag{padding:6px 10px;margin:6px 10px;background-color: rgba(249, 249, 249, 100);;color:rgba(130, 130, 130, 100);}
</style>

