<template>
  <div class="body">
    <QsNavbar :title="item.name" ></QsNavbar>
    <QsHeader>
      <template #right>
        <van-icon name="bars" />
      </template>
    </QsHeader>
    <qs-search :showLogo="false" class="gray-bg"></qs-search>

  </div>
</template>

<script>
import QsSearch from '@components/qs-search'
import QsHeader from '@components/qs-header'
export default {
  name: 'Home',
  components: {
    QsSearch,QsHeader
  },
   data(){
     return{
       pid:0,
       item:{},
       list:[]
     }
   },
  mounted(){
    this.pid = this.$route.query.pid;
    this.onLoad();
  },
  methods:{
    onLoad(){
      this.$ajax.get("client/category/list?pid="+this.pid).then(res=>{
        this.item = res.data.item;
        this.list =res.data.list;
      }).catch(e=>{});
    },
  },
  computed:{

  }
}
</script>

<style lang="scss" scoped>
.gray-bg ::v-deep .van-search{background-color: #F8F9FA;
  .van-cell{background-color: #fff;border-radius: 8px;padding-left: 10px;}
}
</style>
