<template>
  <div class="body">
    <QsNavbar title="产品信息" ></QsNavbar>
    <van-swipe >
      <van-swipe-item v-for="(image, index) in images" :key="index" class="ta-c">
        <img :src="image" class="mw-100" />
      </van-swipe-item>
    </van-swipe>
    <div class="q-box fs-14 p-10 mb-12 c-gray">{{item.desc}}</div>
    <div class="q-box mb-12 product-highlight-box" >
      <div class="header left">
        <div class="title "><b>产品亮点</b></div>
      </div>
      <ul class="body">
        <li v-for="item in highlights" :key="item.id"><span class="c-gray">{{item.title}}：</span>{{item.value}}</li>
      </ul>
    </div>
    <div class="q-box mb-12" >
      <div class="header left">
        <div class="title flex-1 "><b>系列选型</b></div>
        <router-link :to="{path:'/product/list',query:{cid:item.id}}" style="color:#FF0010;font-size:14px">所有产品</router-link>
      </div>
      <van-row style="padding:8px 0;">
        <van-col span="8"  v-for="sItem in series" :key="sItem.id">
          <router-link :to="{path:'/product/list',query:{cid:item.id,sid:sItem.id}}" class="r-box" tag="div" >
              <van-image class="mw-100"  :src="sItem.img"  />
              <div class="title fs-14">{{sItem.title}}</div>
          </router-link>
        </van-col>
      </van-row>
    </div>

    <van-tabs class="q-box q-box-b fs-14">
      <van-tab title="文档" name="document">
        <div v-for="item in docs" :key="item.id" >
          <h3 class="ta-c">{{item.title}}</h3>
          <div v-for="subItem in item.child" :key="subItem.id" class="mb-12">
            <div>{{subItem.title}}</div>
            <ul class="list clearfix">
              <li v-for="thirdItem in subItem.child" :key="thirdItem.id"><a :href="thirdItem.url" >{{thirdItem.title}}</a></li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="特征和行业" name="features"><div v-html="item.features"></div></van-tab>
      <van-tab title="应用行业" name="industry">
        <div v-for="item in industries" :key="item.id" class="industrys">
          <van-cell :title="item.title"  is-link :to="{path:'/industry/show',query:{id:item.id}}" />
          <van-cell :value="subItem.title" v-for="subItem in item.child" :key="subItem.id" class="subItem"/>
        </div>
      </van-tab>
      <van-tab title="更多信息" name="more">
        <div class="body mb-12">
          <h3 class="title ta-c mb-12">概览</h3>
          <div class="c-gray" v-html="item.details"></div>
        </div>
        <div class="body">
           <h3 class="title ta-c ">其它产品</h3>
          <div class="info">
            <router-link :to="{path:sunItem.hasChild?'/category/tree':'/category/show',query:{cid:sunItem.id}}"  tag="div" class="d-flex list-item " v-for="sunItem in other" :key="sunItem.id">
              <img :src="sunItem.logo" class="list-item-product-img"/>
              <div class="flex-1 ml ">
                <div style="margin-bottom:4px;font-size:16px; font-weight: 600;">{{sunItem.name}}</div>
                <div class="c-gray fs-14 van-multi-ellipsis--l2">{{sunItem.desc}}</div>
              </div>
            </router-link>            
          </div>
          
        </div>      
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>

import { Swipe, SwipeItem } from 'vant';
export default {
  name: 'Home',
  components: {
    [Swipe.name]:Swipe,
    [SwipeItem.name]:SwipeItem
  },
   data(){
     return{
       cid:0,
       item:{
         images:''
       },
       highlights:[],
       series:[],
       docs:[],
       industries:[],
       other:[]
     }
   },
  mounted(){
    this.cid = this.$route.query.cid;
    this.onLoad();
  },
  methods:{
    onLoad(){
      this.$ajax.get("client/category/show?id="+this.cid).then(res=>{
        this.item =res.data.item;
        this.highlights = res.data.highlights;
        this.series = res.data.series;
        this.docs = res.data.docs;
        this.industries = res.data.industries;
        this.other = res.data.other;
      }).catch(e=>{});
    },
  },
  computed:{
    images(){
      return this.item.images.split('|')
    }
  }
}
</script>

<style lang="scss">
// .industrys .van-cell{border-bottom:1px solid #ebedf0;}
.industrys .van-cell.subItem{margin-left: 16px;}

.product-highlight-box ul.body{padding-bottom:16px;}
.product-highlight-box ul.body li{font-size:15px;line-height: 21px;}

</style>
