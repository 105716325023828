<template>
  <div class="product-body">
    <QsNavbar :title="post.cid>0?category.name:'产品目录'" ></QsNavbar>
    <template v-if="post.cid>0">
      <qs-search  :customSearch="true" :showAction="true" @search="onSearch" class="b-b-gray">
        <template #action>
            <van-icon name="bars" @click="showPopup=!showPopup"/>
        </template>
      </qs-search>
      </template>
    <template v-else>
      <qs-search :keyword="this.post.keyword" :customSearch="true" @search="onSearch" class="b-b-gray"></qs-search>
    </template>
    <van-list
      class="q-box" id="product-list"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        >
        <QsProductItem :item="item" v-for="item in list" :key="item.id"></QsProductItem>
    </van-list>
    <van-popup v-model="showPopup" position="top"  get-container="#product-list" class="q-box q-box-x">
      <ul class="categories-box">
        <li v-for="item in categories" :key="item.id">
          <van-button :type="item.id==category.id?'primary':'default'" @click="onSwtchCategory(item);">{{item.name}}</van-button>
        </li>
      </ul>
    </van-popup>
    <QsTabbar tab-id="home" group="product"></QsTabbar>
  </div>
</template>

<script>
import QsSearch from '@components/qs-search'
import QsHeader from '@components/qs-header'
import QsProductItem from '@components/qs-product-item'
export default {
  name: 'Home',
  components: {
    QsSearch,QsHeader,QsProductItem
  },
   data(){
     return{
       category:{name:''},
       categories:[],
       list:[],
       showPopup:false,
       loading:true,
       finished:false,
        post:{
            cid:0,
            page:0,
            keyword:""
        },
     }
   },
  created(){
    this.post.cid = this.$route.query.cid||0;
    this.post.keyword = this.$route.query.keyword||'';
    this.onLoad();
  },
  methods:{
    onLoad(){
      this.$ajax.post("client/product/lists",this.post).then(res=>{
        this.category = res.data.category;
        this.categories = res.data.categories;
        this.list = this.list.concat(res.data.list.data);
        this.loading = false;
        if(res.data.list.current_page>=res.data.list.last_page){
            this.finished = true;
        }
      }).catch(e=>{
          console.log(e);
      });
    },
    onSwtchCategory(c){
      if(c.id == this.post.cid){
        return;
      }
      this.showPopup = false;
      this.finished = false;
      this.post.cid = c.id;
      this.post.page=0;
      this.list = [];
    },
    onSearch(keyword){
      this.showPopup = false;
      this.post.keyword = keyword;
      this.post.cid = 0;
      this.finished = false;
      this.post.page = 0;
      this.list = [];
    }
  },
  computed:{

  }
}
</script>

<style lang="scss" scoped>
.q-box.van-list ::v-deep{
  padding-top:8px;position: relative;height: 100%;
  .van-overlay{position: absolute;}
  .van-popup{position: absolute;}
}
.gray-bg ::v-deep .van-search{background-color: #F8F9FA;
  .van-cell{background-color: #fff;border-radius: 8px;padding-left: 10px;}
}
.categories-box{
    list-style: none;
    li{margin:8px;float: left;}
    li button{width:100%;padding:6px 10px;border: 1px solid rgba(234, 234, 234, 100) !important;color:#828282 !important;height:auto;}
    li .van-button--primary{background:#fff !important;border-color:#63C7B7 !important;color:#63C7B7 !important}
    }
</style>
